import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/vitec',
    redirect: '/vitec/activate',
  },
  {
    path: '/vitec/:step',
    name: 'Vitec',
    props: route => ({ ...route.params, ...route.query }),
    component: () => import(/* webpackChunkName: "vitec" */ '../views/Vitec.vue'),
  },
  {
    path: '/webtop',
    redirect: '/webtop/activate',
  },
  {
    path: '/webtop/:step',
    name: 'webtop',
    props: route => ({ ...route.params, ...route.query }),
    component: () => import(/* webpackChunkName: "webtop" */ '../views/Webtop.vue'),
  },
  {
    path: '/webmegler',
    name: 'Webmegler',
    props: route => route.query,
    component: () => import(/* webpackChunkName: "webmegler" */ '../views/Webmegler.vue'),
  },
  {
    path: '/fonn',
    redirect: '/fonn/units',
  },
  {
    path: '/fonn/:step',
    name: 'Fonn',
    props: route => ({ ...route.params, ...route.query }),
    component: () => import(/* webpackChunkName: "fonn" */ '../views/Fonn.vue'),
  },
  {
    name: 'Form',
    path: '/form',
    props: route => route.query,
    component: () => import(/* webpackChunkName: "form" */ '../views/Form.vue'),
  },
  {
    name: 'Finn',
    path: '/finn',
    props: route => route.query,
    component: () => import(/* webpackChunkName: "finn" */ '../views/Finn.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
